export class AddTrackListDM {
  ProductId;// string || int
  OptionId;// string
  SellPrice;// number
  Stock;// number
}

export class RemoveTrackListDM {
  ProductId;// string || int
}
