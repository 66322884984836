<template>
  <div>
    <!-- 社群連結分享 -->
    <div class="sharelinks">
      <a href="#" class="d-block mt-2" @click.prevent="shareLink('facebook')">
        <img src="@/assets/img/Icons-12.svg" alt="facebook icon" class="w-100">
      </a>
      <a href="#" class="d-block mt-2" @click.prevent="shareLink('line')">
        <img src="@/assets/img/Icons-13.svg" alt="line icon" class="w-100">
      </a>
      <a href="#" class="d-block mt-2" @click.prevent="shareLinkModal">
        <img src="@/assets/img/Icons-14.svg" alt="link icon" class="w-100">
      </a>
    </div>
    <!-- 複製商品網址 Modal -->
    <div class="modal shareLinkModal fade" id="shareLink" ref="copyModal">
      <div class="shareModal modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header border-0">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-center pb-6 pt-4 pt-lg-5 px-4 px-md-5 px-lg-6">
            <h4 class="fw-medium text-secondary mb-4">複製商品網址</h4>
            <div class="position-relative">
              <img class="sharelinkIcon position-absolute" src="@/assets/img/Icons-link.svg" alt="sharelink icon">
              <input type="text" class="shareInput form-control ps-7 mb-3 mb-md-4" v-model="shareUrl" readonly>
              <button type="button" @click.prevent="shareLink('currentLink')" class="btn btn-primary py-1 px-7 rounded-0 text-white">確定</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal'

export default {
  data () {
    return {
      copyLinkModal: '',
      shareUrl: '',
      productName: ''
    }
  },
  props: ['productId', 'name'],
  methods: {
    shareLink (social) { //* 社群分享
      if (!social) {
        return ''
      }

      if (social === 'facebook') {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.shareUrl}`)
      } else if (social === 'line') {
        if (document.body.clientWidth < 576) {
          // ? 手機版
          const urlStringMobile = encodeURIComponent(`我在 papawash 發現一個很棒的商品「${this.name}」，分享給你！\n${this.shareUrl}`)
          window.open(`line://msg/text/${urlStringMobile}`, '_blank')
        } else {
          // ? web 版
          const urlString = encodeURIComponent(`${this.shareUrl}`)
          const text = encodeURIComponent(`我在 papawash 發現一個很棒的商品「${this.name}」，分享給你！`)
          window.open(`https://social-plugins.line.me/lineit/share?url=${urlString}&text=${text}`)
        }
      } else if (social === 'currentLink') {
        const inputLink = document.querySelector('.shareInput')
        inputLink.focus()
        setTimeout(() => {
          inputLink.selectionStart = 0
          inputLink.selectionEnd = this.shareUrl.length
          inputLink.select()
          document.execCommand('copy')
        }, 100)
        setTimeout(() => {
          this.copyLinkModal.hide()
          this.$swal.fire({
            title: '已複製網址連結',
            showConfirmButton: false,
            timer: 1000,
            customClass: {
              title: 'text-class'
            }
          })
        }, 800)
      }
    },
    shareLinkModal () { //* 開啟複製連結 modal
      this.copyLinkModal.show()
    }
  },
  watch: {
    productId () {
      this.shareUrl = `https://www.papawash.com/product/${this.productId}`
    },
    name () {
      this.productName = this.name
    }
  },
  mounted () {
    this.copyLinkModal = new Modal(this.$refs.copyModal)
    this.shareUrl = `https://www.papawash.com/product/${this.productId}` // ?取得網址
  }
}
</script>

<style lang="scss" scoped>
.sharelinkIcon{
  top: 7px;
  left: 9px;
  width: 24px;
  height: 24px;
}

.shareInput:focus{
  border-color: #1479FB;
  border-width: 2px;
}

.shareInput::selection{
  background: #bdd6fb;
}

.shareInput::-moz-selection{
  background: #bdd6fb;
}
</style>
