import axios from 'axios'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { AddTrackListDM, RemoveTrackListDM } from '@/model/data/trackList-data-model'

const trackList = {
  //* 新增願望清單項目
  AddToTrackList: async function (item) {
    try {
      const url = `${process.env.VUE_APP_API}/api/wishlist/add`
      const data = new AddTrackListDM()
      data.ProductId = item.id || item.productId
      data.OptionId = item.optionId || '123'
      data.SellPrice = item.price
      data.Stock = item.stock || 1
      const rsp = await axios.post(url, data)

      if (rsp === null || rsp.data === null) {
        return null
      }
      if (rsp.data.rtnCode !== 0) {
        Swal.fire({
          title: `${rsp.data.rtnMsg}(${rsp.data.rtnCode})`,
          confirmButtonColor: '#003894',
          customClass: {
            title: 'text-class'
          }
        })
        return null
      }

      Swal.fire({
        toast: true,
        position: 'center',
        title: '商品已加入追蹤清單',
        showConfirmButton: false,
        timer: 1500,
        width: 500,
        background: '#F0F0F2',
        padding: 25
      })

      return true
    } catch (err) {
      if (err.response.status === 401) {
        if (document.cookie.replace(/(?:(?:^|.*;\s*)PaPaLoginToken\s*=\s*([^;]*).*$)|^.*$/, '$1')) {
          return trackList.AddToTrackList(item)
        }
      }
    }
  },
  //* 移除願望清單項目
  RemoveToTrackList: async function (item) {
    try {
      const url = `${process.env.VUE_APP_API}/api/wishlist/remove`
      const data = new RemoveTrackListDM()
      data.ProductId = item.id || item.productId
      const rsp = await axios.post(url, data)

      if (rsp === null || rsp.data === null) {
        return null
      }
      if (rsp.data.rtnCode !== 0) {
        Swal.fire({
          title: `${rsp.data.rtnMsg}(${rsp.data.rtnCode})`,
          confirmButtonColor: '#003894',
          customClass: {
            title: 'text-class'
          }
        })
        return null
      }

      Swal.fire({
        toast: true,
        position: 'center',
        title: '商品已移除追蹤清單',
        showConfirmButton: false,
        timer: 1500,
        width: 500,
        background: '#F0F0F2',
        padding: 25
      })

      return true
    } catch (err) {
      if (err.response.status === 401) {
        if (document.cookie.replace(/(?:(?:^|.*;\s*)PaPaLoginToken\s*=\s*([^;]*).*$)|^.*$/, '$1')) {
          return trackList.RemoveToTrackList(item)
        }
      }
    }
  },
  //* 查詢產品是否存在願望清單
  CheckInTrackList: async function (id) {
    try {
      const url = `${process.env.VUE_APP_API}/api/wishlist/find?productid=${id}`
      const rsp = await axios.get(url)
      if (rsp == null || rsp.data == null || rsp.data.rtnCode !== 0) {
        return null
      }

      return true
    } catch (err) {
      if (err.response.status === 401) {
        if (document.cookie.replace(/(?:(?:^|.*;\s*)PaPaLoginToken\s*=\s*([^;]*).*$)|^.*$/, '$1')) {
          return trackList.CheckInTrackList(id)
        }
      }
    }
  }
}

export default trackList
